import PropTypes from "prop-types"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
// import IconButton from "@material-ui/core/IconButton"
// import MenuIcon from "@material-ui/icons/Menu"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },
  appbar: {
    //backgroundColor: "#222333", //theme.palette.primary, //"#1976D2",
    zIndex: 2,
  },
  phoneNumber: {
    whiteSpace: "nowrap",
    color: "#fff",
    textDecorationColor: "#15D48E",
  },
  address: {
    whiteSpace: "nowrap",
    color: "#fff",
    textDecoration: "none",
  },
}))

const Header = ({ siteTitle }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appbar + " headerBg"}>
        <div
          className="box right bottom"
          style={{ height: "80px", width: "80px", opacity: 0.1 }}
        ></div>
        <div
          className="box left bottom"
          style={{ height: "80px", width: "80px", opacity: 0.1 }}
        ></div>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Per prenotare chiama:{" "}
            <a href="tel:+39 335-6115402" className={classes.phoneNumber}>
              +39 335-6115402
            </a>{" "}
            <br />
            <a href="mailto:egilavagna@gmail.com" className={classes.address}>
              egilavagna@gmail.com
            </a>
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
